<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{$t('Sign Mandate')}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding nk-text-muted">
    <div v-if="mandateLink.length">
      <p>{{ $t('sign_mandate_modal.copy_the_link_instruction') }}</p>
      <code class="d-block mb-2 p-2 border">{{mandateLink}}</code>
      <alert color="info" v-if="displayMandateLink">{{ $t('sign_mandate_modal.mandate_link_expiry_note') }}</alert>
    </div>
    <div v-else class="text-center text-muted">
      <div class="h1">
        <nio-icon icon="ni-info text-muted"></nio-icon>
      </div>
      <div>
        <p>{{ $t('sign_mandate_modal.text1')+' '+$t('sign_mandate_modal.text3') }}</p>
        <p>{{ $t('sign_mandate_modal.text2', {provider: mandateProvider}) }}</p>
        <p class="alert alert-warning">{{ $t('registration.mandate_message1') }}</p>
        <hr>
        <p class="text-left">{{ $t('sign_mandate_modal.generate_link_instruction1')}}</p>
        <div class="d-flex align-items-center text-left">
          <b-check-box v-model="displayMandateLink" id="gn_m_link_c_box"></b-check-box>
          <label class="m-0 ml-1" for="gn_m_link_c_box">{{ $t('Payments are not made by me') }}</label>
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer class="ion-padding">
    <nk-button type="dark" v-on:click="signMandate" class="mr-2" v-if="!mandateLink.length">{{$t(displayMandateLink ? 'Generate mandate link' : 'Sign mandate')}}</nk-button>
    <nk-button type="light" v-on:click="F_logout">{{$t('Sign out')}}</nk-button>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  loadingController,
  modalController,
  toastController
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import axios from "@/libs/axios";
import useLogout from "@core/comp-functions/Logout"
import {useI18n} from "vue-i18n";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import Alert from "@core/components/alert/Alert";
import storeHelper from '@/store/helper'

export default defineComponent({
  name: 'SignMandateModal',
  components: {Alert, BCheckBox, IonContent, IonHeader, IonTitle, IonToolbar },
  setup(){

    const{t} = useI18n()
    const{endPointByRole} = storeHelper()
    const {logout} = useLogout()
    let displayMandateLink = ref(false)
    let mandateLink = ref('')
    const signMandate = async () => {

      let loader = await loadingController.create({message: t('Please wait...')})
      await loader.present()

      axios.get(endPointByRole+'/sign_mandate')
          .then(resp => {
            if( !resp.data.success){
              toastController.create({color: "danger", message: resp.data.message, duration: 3000}).then( toast => toast.present())
            }
            else{
              if(displayMandateLink.value){
                mandateLink.value = resp.data.data.url
              }
              else{
                window.location.href = resp.data.data.url
              }
            }
          })
          .catch(error => {
            toastController.create({color: "danger", message: t('errors.general_error'), duration: 2500}).then( toast => toast.present())
            console.log('Mandate signing ajax error: '+error)
          })
          .then(()=> loader.dismiss())
    }
    const mandateProvider = process.env.VUE_APP_PAYMENT_PROVIDER
    const mandateArticle = ()=>{
      switch (process.env.VUE_APP_PAYMENT_PROVIDER){
        case 'Mollie':
        case 'Buckaroo':
        default:
          return 'a'
      }
    }
    const F_logout = async () => {
      await modalController.dismiss()
      logout()
    }
    return {
      displayMandateLink,
      F_logout,
      mandateArticle,
      mandateLink,
      mandateProvider,
      signMandate,
    }
  }
});
</script>
