export default [
  {
    header: 'Main Menu',
  },
  {
    title: 'Dashboards',
    icon: 'ni-dashboard',
    route: 'admin'
  },
  {
    title: 'Leads Management',
    icon: 'ni ni-list',
    group_id: 'leads_management',
    css_class_hybrid: 'ion-list-sub-menu', // for mobile app
    children: [
      {
        title: 'Leads List',
        route: 'adminLeadsList',
        mobile_icon: 'ni-view-list-fill',
      },
      {
        title: 'Returned Leads',
        route: 'adminReturnedLeads',
        mobile_icon: 'ni-folder-minus',
      },
      {
        title: 'Assigned Leads',
        route: 'adminAssignedLeads',
        mobile_icon: 'ni-folder-check',
      },
      {
        title: 'Export Leads',
        route: 'ExportLeadsCSV',
        mobile_icon: 'ni-file-xls',
      },
    ],
  },
  {
    title: 'Advisors',
    icon: 'ni ni-list-index',
    route: 'advisors',
  },
  {
    title: 'Marketplace',
    icon: 'ni ni-cart-fill',
    route: 'adminMarketplace',
  },
  {
    title: 'Invoices',
    icon: 'ni ni-file-docs',
    group_id: 'invoices_management',
    children: [
      {
        title: 'Invoices',
        route: 'adminInvoices',
        mobile_icon: 'ni-file-docs',
      },
      {
        title: 'States',
        route: 'adminInvoicesStates',
        mobile_icon: 'ni-growth-fill',
      },
    ],
  },
  {
    title: 'Appointment Agents',
    icon: 'ni ni-user-list',
    group_id: 'ap_agents',
    children: [
      {
        title: 'Agents',
        route: 'adminAPAgentsList',
        mobile_icon: 'ni-user-list-fill',
      },
      {
        title: 'Appointments',
        route: 'adminApAgentsAppointments',
        mobile_icon: 'ni-clipboad-check-fill',
      },
      {
        title: 'Leads',
        route: 'adminApAgentsLeads',
        mobile_icon: 'ni-folder-list',
      },
    ],
  },
  {
    title: 'Companies',
    icon: 'ni ni-building',
    group_id: 'adm_companies',
    children: [
      {
        title: 'List',
        route: 'adminCompaniesList',
        mobile_icon: 'ni-building-fill',
      },
    ],
  },
  {
    title: 'Knowledge Base',
    icon: 'ni ni-book-read',
    group_id: 'knowledge_base',
    css_class_hybrid: 'ion-list-sub-menu',
    children: [
      {
        title: 'Categories',
        route: 'adminKnowledgeBaseCatList',
        mobile_icon: 'ni-view-grid-wd',
      },
      {
        title: 'Posts',
        route: 'adminKnowledgeBaseTopicsList',
        mobile_icon: 'ni-view-x2',
      },
    ],
  },
  {
    title: 'Leads Forms',
    icon: 'ni ni-view-group-fill',
    route: 'adminLeadsForms',
  },
  {
    title: 'Settings',
    icon: 'ni ni-setting-alt',
    group_id: 'admin_settings',
    css_class_hybrid: 'ion-list-sub-menu',
    children: [
      {
        title: 'API Keys',
        route: 'adminAPIKeys',
        mobile_icon: 'ni-lock-fill',
      },
      {
        title: 'Blocked phone numbers',
        route: 'adminBlockedPhoneNumbers',
        mobile_icon: 'ni-hash',
      },
      {
        title: 'Email Templates',
        route: 'adminEmailTemplates',
        mobile_icon: 'ni-emails-fill',
      },
    ],
  },
]
