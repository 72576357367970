<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ $t('Lead')+' '+$t('Follow up') }}</ion-title>
      <ion-buttons slot="end">
        <ion-button v-if="!isDesktop" @click="closeModal">Close</ion-button>
        <a v-else href="javascript:;" class="close text-secondary" @click="closeModal"><nio-icon icon="ni-cross-sm"></nio-icon></a>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <template v-if="leadData.next_followup.future_date || leadData.next_followup.type === 'Appointment'">
      <template v-if="leadData.next_followup.future_date">
        <h4 style="color: #00c58c;">{{ $t("lead_followup_wizard.upcoming_followup_type_reminder", {followup_type: $t(leadData.next_followup.type).toLowerCase()})}}</h4>
        <p> {{$t(leadData.next_followup.type)+' '+$t('at')+' '+leadData.next_followup.future_date}}</p>
      </template>
      <template v-else>
        <h4 style="color: #00c58c;">{{ $t("lead_followup_wizard.how_was_your_appointment")}} {{' #'+leadData.lead_number}}</h4>
        <p>
          {{ $t('lead_followup_wizard.you_had_a_followup_how_did_it_go', {date: leadData.last_followup.date, time: leadData.last_followup.time, followup_type: $t('Appointment').toLowerCase()})}}
        </p>
      </template>
    </template>
    <template v-else>
      <h3 style="color: #00c58c;">{{ $t("It's time to reach out to your lead!")+' #'+leadData.lead_number}}</h3>
      <p v-if="leadData.last_followup.time && leadData.next_followup.date">
        {{ $t('lead_followup_wizard.you_had_a_followup_how_did_it_go', {date: leadData.last_followup.date, time: leadData.last_followup.time, followup_type: $t('Follow up').toLowerCase()})}}
      </p>
      <p v-else-if="leadData.last_followup.time">
        {{$t('lead_followup_wizard.last_followup_unreachable_lead_try_again_if_u_want', {date: leadData.last_followup.date, time: leadData.last_followup.time})}}
      </p>
      <p v-else>{{ $t('lead_followup_modal.followup_asap_is_crucial_msg')}}<br/>{{ $t('lead_followup_modal.time_since_received_lead', {time: leadData.received_time}) }}</p>
    </template>
    <div class="mt-5" v-if="$isHybridPlatform">
      <nk-button type="dark" v-on:click="viewLeadDetails" class="mr-2">{{$t('View Details')}}</nk-button>
      <nk-button type="light" v-on:click="remindLater">{{$t('Remind Later')}}</nk-button>
    </div>
  </ion-content>
  <ion-footer class="ion-padding" v-if="!$isHybridPlatform">
    <nk-button type="dark" v-on:click="viewLeadDetails" class="mr-2">{{$t('View Details')}}</nk-button>
    <nk-button type="light" v-on:click="remindLater">{{$t('Remind Later')}}</nk-button>
  </ion-footer>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonFooter,
  isPlatform,
  toastController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import axios from "@/libs/axios";

export default defineComponent({
  components: {IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonFooter},
  props:{
    leadData: Object,
    modalOpen: Boolean,
  },
  setup(props){

    const isDesktop = isPlatform('desktop')
    const store = useStore()
    const router = useRouter()

    const closeModal = ()=>{
      store.commit('advisorLeadsFollowup/UPDATE_MODAL_OPEN', false)
    }

    const remindLater = ()=>{
      closeModal()
      store.commit('advisorLeadsFollowup/DONT_SHOW_POPUP', true)
      axios.put('/api/advisor/leads/followup_suggestions/remind_later')
          .then(()=>{})
          .catch(error => {
            if(error.response.data){
              toastController.create({color: "danger", message: error.response.data.message, duration: 3500}).then( toast => toast.present())
            }
          })
    }

    const viewLeadDetails = ()=>{
      closeModal()
      store.commit('advisorLeadsFollowup/DONT_SHOW_POPUP', true)
      router.push({name: 'LeadDetails', params:{id: props.leadData.id}})
    }

    return{
      closeModal,
      isDesktop,
      remindLater,
      store,
      viewLeadDetails,
    }
  }
});
</script>
