export default {
    namespaced: true,
    state: {
        admin: {
            assigned_leads: false,
            leads_list: false,
            marketplace: false,
            returned_leads: false,
        },
        advisor: {
            leads_list: false,
            returned_leads: false,
            archived_leads: false,
        },
        email_templates: false,
    },
    mutations: {
        UPDATE_TRACKER(state, data){
            if(data.interface){
                state[data.interface][data.tracker] = data.status
            }
            else{
                state[data.tracker] = data.status
            }
        },
    },
}