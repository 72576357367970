<template>
  <a href="javascript:;" v-if="isLink" class="btn" :class="classesList">
    <slot></slot>
  </a>
  <button v-else class="btn" :class="classesList">
    <slot></slot>
  </button>
</template>

<script>
import {computed} from "vue";

export default {
  props: {
    isLink: Boolean,
    dim: Boolean,
    outline: Boolean,
    type: {
      type : String,
      required: true,
    },
    round: {
      type: Boolean,
    },
    isIconOnlyButton: Boolean,
    size: undefined,
    fullWidth: Boolean,
  },
  setup(props){

    const classesList = computed(()=> {

      let classesList = []
      if(props.outline){
        classesList.push(`btn-outline-${props.type}`)
      }
      else{
        classesList.push(`btn-${props.type}`);
      }
      props.dim ? classesList.push('btn-dim') : ''
      props.size !== undefined ? classesList.push(`btn-${props.size}`) : ''
      props.round ? classesList.push('btn-round') : ''
      props.isIconOnlyButton ? classesList.push('btn-icon') : ''
      props.fullWidth ? classesList.push('btn-block') : ''
      return classesList
    })


    return {
      classesList,
    }
  }
}
</script>
