import store from "@/store";
import {useRouter} from "vue-router"

export default () => {
    const router = useRouter()
    const logout = () => {
        store.dispatch('auth/auth_logout')
            .then(async ()=> {
                await router.push({name: store.state.auth.user_role === 'is-admin' ? 'adminLogin' : 'Login'})
            })
    }
    return {
        logout,
    }
}