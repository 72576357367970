export default [
    {
        path: '/company/home',
        name: 'companyHome',
        component: () => import("@/views/admin/advisors/Advisors"),
    },
    {
        path: '/company/advisors',
        name: 'comAdvisorsList',
        component: () => import("@/views/admin/advisors/Advisors"),
    },
    {
        path: "/company/advisors/profile/:id/:tab?",
        name: "comAdvisorProfile",
        component: () => import('@/views/admin/advisors/Profile'),
        meta: {
            required_roles: ['is-company'],
            redirect_to: {
                'is-advisor': 'Home',
                'is-admin': 'advisorProfile',
            },
        },
    },
    {
        path: '/company/invoices',
        name: 'comInvoices',
        component: () => import("@/views/invoices/Invoices"),
    },
    {
        path: '/company/invoices/:id',
        name: 'comViewInvoice',
        component: () => import('@/views/invoice/ViewInvoice'),
        meta:{
            required_roles: ['is-company'],
            redirect_to: {
                'is-admin': 'adminViewInvoice',
                'is-advisor': 'ViewInvoice',
            }
        }
    },
    {
        path: '/company/settings/:tab?',
        name: 'comSettings',
        component: () => import('@/views/company/Settings'),
        meta:{
            required_roles: ['is-company'],
            redirect_to: {
                'is-admin': 'adminProfile',
                'is-advisor': 'Settings',
            }
        }
    },
    {
        path: '/company/lead/:id',
        name: 'comLeadDetails',
        component: () => import('@/views/LeadDetails'),
        meta:{
            required_roles: ['is-company'],
            redirect_to: {
                'is-advisor': 'LeadDetails',
                'is-admin': 'adminLeadDetails',
            }
        }
    },
]