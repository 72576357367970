import axios from "@/libs/axios";
import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import i18n from "@/libs/i18n";

export default () => {
    const isDesktop = isPlatform('desktop')
    const getCategories = () => {
        return new Promise((resolve, reject) => {
            axios.get('/api/email_templates/categories/get')
                .then( (resp) => {
                    resolve(resp.data)
                })
                .catch( err => {
                    reject(err)
                })
        })
    }

    let cancelToken = false
    const getTemplates = ( payload ) => {
        if(false !== cancelToken){
            cancelToken()
            cancelToken = false
        }
        return new Promise( (resolve, reject) => {
            axios.post('/api/email_templates/list', payload, {
                cancelToken: new axios.CancelToken( c => {
                    cancelToken = c
                })
            })
                .then(r => {
                    cancelToken = false
                    if(r.data.success){
                        resolve(r.data)
                    }
                    else{
                        reject(r.data.message)
                    }
                })
                .catch(r => {
                    cancelToken = false
                    reject(r)
                })
        })
    }
    const getTemplateData = (id) => {
        return new Promise((resolve, reject) => {
            axios.get('/api/email_templates/get/'+id)
                .then( (resp) => {
                    resolve(resp.data)
                })
                .catch( err => {
                    reject(err)
                })
        })
    }
    const saveTemplate = (data) => {
        return new Promise((resolve, reject) => {
            axios.post('/api/email_templates/set', data)
                .then( (resp) => {
                    resolve(resp.data)
                })
                .catch( err => {
                    reject(err)
                })
        })
    }
    const advisorSendQuickEmailToLead = async ( templateId, AL_id ) => {
        const iA = await alertController.create({
            cssClass: isDesktop ? 'alert-web' : '',
            header: i18n.global.t('Send Email'), message: i18n.global.t('Are you sure to send this email to the lead?'),
            buttons: [
                {
                    text: i18n.global.t('Cancel'),
                    cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                },
                {
                    cssClass: isDesktop ? 'alert-btn-primary' : '',
                    text: i18n.global.t('Send'),
                    handler: async () => {
                        const l = await loadingController.create({message:i18n.global.t('Sending...')})
                        await l.present()
                        const t = await toastController.create({header: i18n.t('Error')+"!", duration: 3500, position: "top", color: "danger"})
                        axios.post('/api/advisor/gmail/send', {template_id: templateId, AL_id: AL_id})
                            .then(r => {
                                if(r.data.success){
                                    t.message = i18n.global.t('thank_you_messages.email_sent')
                                    t.header = i18n.global.t('Success')
                                    t.color = "tertiary"
                                }
                                else{
                                    t.message = r.data.message
                                }
                                t.present()
                            })
                            .catch(er => {
                                t.message = er.response ? er.response.status+' '+er.response.statusText : er
                                t.present()
                            })
                            .then(()=> l.dismiss())
                    }
                },
            ]
        })
        await iA.present()
    }
    const templateInitialData = {
        id: 0,
        info: '',
        name: '-----------------',
        user_id: -1,
        subject: '',
        body: '',
    }
    const deleteTemplate = async ( id ) => {
        return new Promise((resolve, reject) => {
            alertController.create({
                cssClass: isDesktop ? 'alert-web' : '',
                header: i18n.global.t('Delete')+' '+i18n.global.t('Template'), message: i18n.global.t('Are you sure to delete this template?'),
                buttons: [
                    {
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                        text: i18n.global.t('Cancel'), handler: ()=> reject()
                    },
                    {
                        cssClass: isDesktop ? 'alert-btn-danger' : '',
                        text: "Delete",
                        handler: async () => {
                            const l = await loadingController.create({message:i18n.global.t('Processing...')})
                            await l.present()
                            const t = await toastController.create({header: i18n.global.t('Error')+'!', duration: 3500, position: "top", color: "danger"})
                            axios.post('/api/email_templates/delete', {id: id})
                                .then(r => {
                                    if(r.data.success){
                                        t.message = i18n.global.t('thank_you_messages.email_template_deleted')
                                        t.header = i18n.global.t('Success')
                                        t.color = "tertiary"
                                        resolve()
                                    }
                                    else{
                                        t.message = r.data.message
                                        reject()
                                    }
                                    t.present()
                                })
                                .catch(er => {
                                    t.message = er.response ? er.response.status+' '+er.response.statusText : er
                                    t.present()
                                    reject()
                                })
                                .then(()=> l.dismiss())
                        }
                    },
                ]
            })
                .then((a)=> a.present())
        })
    }

    const getPlaceholders = async () => {
        return new Promise((resolve, reject) => {
            axios.get('/api/email_templates/placeholders')
                .then( (resp) => {
                    if(!resp.data.success){
                        reject(resp.data.message)
                    }
                    else{
                        resolve(resp.data)
                    }
                })
                .catch( err => {
                    reject(err)
                })
        })
    }

    return{
        advisorSendQuickEmailToLead,
        deleteTemplate,
        getCategories,
        getPlaceholders,
        getTemplateData,
        getTemplates,
        saveTemplate,
        templateInitialData,
    }
}