<template>
  <ion-app>
    <!-- menu for native app only -->
    <vertical-menu-mobile v-if="$isHybridPlatform && !noLeftNavRoutes.includes($route.name || '')"></vertical-menu-mobile>
    <ion-router-outlet :animation="isDesktop ? c_animation : ''" id="crm-router-outlet"/>
  </ion-app>
</template>

<script>
import {IonApp, IonRouterOutlet, createAnimation, isPlatform} from '@ionic/vue';
import {defineComponent, onBeforeMount, onMounted, watch} from 'vue';
//import axios from "@/libs/axios";
import { useStore} from "vuex"
import {initiateEcho} from "@/libs/laravel_echo";
import { getPlatforms } from '@ionic/vue';
import usePingServer from "@core/comp-functions/PingServer"
//import useSleekPlan from "@/libs/sleek-plan"
import usePushNotifications from "@/push_notifications"
import VerticalMenuMobile from "@core/layouts/vertical-menu-mobile/VerticalMenuMobile";

export default defineComponent({
  name: 'App',
  components: {
    VerticalMenuMobile,
    IonApp,
    IonRouterOutlet,
  },
  setup(){

    const isDesktop = isPlatform('desktop')
    const store  = useStore()
    const{pingServer} = usePingServer(store)
    // routes names, which has no left side menu
    const noLeftNavRoutes = [
        'Login',
        'Register',
        'ViewKnowledgeBase',
        'ForgotPassword',
        'TOS',
        'MaintenanceMode',
        'complete_reg',
        'PrivacyPolicy',
        '404',
    ]
    //const{initSleekPlan} = useSleekPlan(store)
    const c_animation = (baseEls, opts) => {
      return createAnimation()
          .addElement(opts.enteringEl)
          .duration(500)
          .keyframes([
            { offset: 0, opacity: '0'},
            { offset: 0.5, opacity: '0.50'},
            { offset: 1, opacity: '1'},
          ]);
    }
    const{initiatePushNotifications} = usePushNotifications()

    // Get some general data, required throughout the app
    // The data is fetched only once, when the user status in vuex store is changed to as "logged in"
    watch(()=>store.state.admin_data, (n)=>{
      if(parseInt(n.id) > 0){
        // Initialize the laravel echo + pusher
        initiateEcho()
      }
    })
    watch(()=>store.state.advisor_data, (n)=>{
      if(parseInt(n.id) > 0){
        // Initialize the laravel echo + pusher
        initiateEcho()
      }
    })

    // Dark mode
    watch( ()=> store.state.appConfig.dark_mode, (n) => {
      if(n){
        document.getElementsByTagName('body')[0].classList.add('dark-mode')
      }
      else{
        document.getElementsByTagName('body')[0].classList.remove('dark-mode')
      }
    })
    // If user has not yet chosen a mode in the app, then consider the OS color scheme
    if(null === localStorage.getItem('dark_mode')){
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      store.state.appConfig.dark_mode = prefersDark.matches
      // Listen for changes to the prefers-color-scheme media query
      try {
        prefersDark.addEventListener('change', (e) => {
          store.state.appConfig.dark_mode = e.matches
        });
      } catch (e1) {
        prefersDark.addListener((mediaQuery) => {
          if(null === localStorage.getItem('dark_mode')){
            store.state.appConfig.dark_mode = mediaQuery.matches
          }
        });
      }
    }
    else{
      // Otherwise respect what user has chosen in the app
      store.state.appConfig.dark_mode = localStorage.getItem('dark_mode') === '1'
    }

    onMounted(()=>{
      //initSleekPlan()
      initiatePushNotifications()
    })

    onBeforeMount(()=>{
      pingServer()
      const platform = getPlatforms()
      store.state.current_platform.general = platform.includes('mobile') ? 'mobile' : 'web'
      let os = ''
      if(platform.includes('ios')){
        os = 'ios'
      }
      else if( platform.includes('android')){
        os = 'android'
      }
      store.state.current_platform.specific = os
    })
    return{
      c_animation,
      isDesktop,
      noLeftNavRoutes,
    }
  },
});
</script>
