<template>
  <ion-menu content-id="crm-router-outlet" class="ion-app-main-menu">
    <ion-header class="ion-no-border">
      <ion-toolbar class="is-ion-safe-area">
        <ion-buttons slot="start">
          <ion-button v-on:click="$router.push('/')">
            <img class="logo-img" :src="require('@/assets/images/logos/logo-small.png')" :srcset="require('@/assets/images/logos/logo-small2x.png')+' 2x'" alt="logo-dark">
          </ion-button>
        </ion-buttons>
        <ion-title>Leads CRM</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <template v-for="(item,iND) in menuItems" :key="'mi'+iND">
        <template v-if="(undefined === item.display || item.display) && !item.header">
          <ion-list v-if="item.children" :inset="false" :class="item.css_class_hybrid || ['ion-list-sub-menu']">
            <ion-list-header>
              <ion-label class="overline-title text-primary-alt">{{ $t(item.title) }}</ion-label>
            </ion-list-header>
            <ion-menu-toggle>
              <ion-item v-for="ch in item.children" :key="ch.title"
                        button details="true"
                        lines="none"
                        v-on:click="$router.push({name: ch.route})"
                        :class="{active: $route.name === ch.route}">
                <nio-icon v-if="ch.mobile_icon" :icon="ch.mobile_icon" slot="start" size="1.5rem"></nio-icon>
                <ion-label>{{ $t(ch.title) }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <ion-menu-toggle v-else>
            <ion-item v-on:click="$router.push({name:item.route})" button details="true" lines="none"
                      :class="{active: $route.name === item.route}">
              <nio-icon :icon="item.icon" slot="start"></nio-icon>
              <ion-label>{{ $t(item.title) }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </template>
      </template>
      <ion-item v-on:click="F_Logout" button details="true" lines="none" style="--detail-icon-color:red">
        <nio-icon icon="ni-signout" slot="start" color="#ec7171" style="font-size: 1.5rem"></nio-icon>
        <ion-label style="--color:#ec7171">{{ $t('Sign out') }}</ion-label>
      </ion-item>
    </ion-content>
  </ion-menu>
</template>

<script>
import {IonContent,IonList, IonListHeader, IonItem, IonLabel, IonMenuToggle, IonMenu, IonHeader, IonToolbar, IonButtons,
  IonButton, IonTitle, menuController} from '@ionic/vue'
import {computed} from "vue";
import admin from "@/navigation/vertical/admin";
import apAgent from "@/navigation/vertical/ap-agent";
import companyMenu from "@/navigation/vertical/company";
import {useStore} from "vuex";
import useAdvisorMenu from "@/navigation/vertical/basic";
import NioIcon from "@core/components/nio-icon/NioIcon";
import useLogout from "@core/comp-functions/Logout";

export default {
  components:{
    NioIcon,
    IonContent,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonMenuToggle,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
  },
  setup(){
    const store = useStore()
    const{advisorMenu} = useAdvisorMenu()
    const {logout} = useLogout()
    const menuItems = computed(()=>{
      let m = [];
      switch (store.state.auth.user_role){
        case 'is-advisor':
          m = advisorMenu
          break;
        case 'is-admin':
          m = admin
          break;
        case 'is-ap-agent':
          m = apAgent
          break;
        case 'is-company':
          m = companyMenu
          break;
        default:
          m = []
          break;
      }
      return m;
    })

    const F_Logout = () => {
      menuController.close().then(()=> logout())
    }

    return{
      F_Logout,
      menuItems,
    }
  }
}
</script>
