<template>
  <div class="custom-control custom-checkbox" :class="[{'notext' : !label.length},['custom-control-'+size]]">
    <input type="checkbox" class="custom-control-input"
           :id="id"
           :checked="checked || modelValue" @change="$emit('update:modelValue', $event.target.checked)"
           :disabled="disabled">
    <label class="custom-control-label" :class="{'form-label': (size !== 'sm')}" :for="id">{{label}}</label>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    checked: Boolean,
    id: {
      type: String,
      required: true,
    },
    disabled: Boolean,
    size: {
      type: String,
      default: 'sm'
    },
  },
})
</script>
