export default [
  {
    header: 'Main Menu',
  },
  {
    title: 'Advisors',
    icon: 'ni ni-list-index',
    route: 'comAdvisorsList'
  },
  {
    title: 'Invoices',
    icon: 'ni ni-file-docs',
    route: 'comInvoices',
  },
  {
    title: 'Export Leads',
    icon: 'ni ni-file-xls',
    route: 'ExportLeadsCSV',
  },
]