import axios from "@/libs/axios"

export default {
    namespaced: true,
    state: {
        status: '',
        is_refreshing: false,
        refreshing_call: undefined,
        is_logged_in: !!localStorage.getItem('user_role'),
        user_role: localStorage.getItem('user_role') || '',
        after_auth_data_loaded: false,
    },
    actions: {
        auth_request: ({commit}, data) => {
            return new Promise((resolve, reject) => { // The Promise used for router redirect in login
                commit('AUTH_REQUEST')
                axios.post('/oauth/token',data)
                    .then(async resp => {

                        commit('AUTH_SUCCESS', resp.data)

                        //await dispatch('get_general_data', null, {root: true})
                        //dispatch('notifications/getNotifications', null, {root: true})
                        //dispatch('get_logged_in_user_profile', {role: resp.data.role}, {root: true})
                        //    .then(()=> resolve(resp))
                        //    .catch(er=> reject(er))
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('AUTH_ERROR', err)
                        reject(err)
                    })
            })
        },
        refresh_token({commit, state}/*, callback*/ ) {
            if (state.is_refreshing) {
                const chained = state.refreshing_call.then( /*callback*/ );
                commit('set_refreshing_call', chained);
                return chained;
            }
            commit('set_refreshing_state', true);
            const data = {
                grant_type: 'refresh_token',
                refresh_token: localStorage.getItem('refresh_token'),
                client_id: process.env.VUE_APP_API_CLIENT_ID,
                client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
            }
            const refreshing_call = axios.post('/oauth/token', data, {is_refresh_token: true})
                .then(resp => {
                    commit('AUTH_SUCCESS', resp.data)
                    commit('set_refreshing_state', false);
                    commit('set_refreshing_call', undefined);
                    return Promise.resolve(resp.data.access_token);
                })
                .catch((err)=>{
                    console.log('Error while refreshing access token: ', err)
                })
                .then(/*callback*/)
            commit('set_refreshing_call', refreshing_call);
            return refreshing_call;
        },
        auth_logout: ({commit}) => {
            return new Promise((resolve) => {
                commit("AUTH_LOGOUT")
                commit("commit_after_auth_data_loaded", false)
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('user_role')
                // remove the axios default header
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
    },
    mutations: {
        AUTH_LOGOUT: (state) => {
            state.is_logged_in = false
        },
        AUTH_REQUEST: (state) => {
            state.status = 'loading'
        },
        AUTH_SUCCESS: (state, data ) => {
            state.status = 'success'
            state.is_logged_in = true
            if(data.access_token){
                localStorage.setItem('access_token', data.access_token)
            }
            if(data.refresh_token){
                localStorage.setItem('refresh_token', data.refresh_token)
            }
            if(false !== data.role){
                state.user_role = data.role
                localStorage.setItem('user_role', data.role)
            }
        },
        set_refreshing_call: (state, chained ) => {
            state.refreshing_call = chained
        },
        set_refreshing_state: (state, val) => {
            state.is_refreshing = val
        },
        commit_after_auth_data_loaded(state, isLoaded){
            state.after_auth_data_loaded = isLoaded
        }
    },
}
