export default [
  {
    header: 'Main Menu',
  },
  {
    title: 'Overview',
    icon: 'ni-dashboard',
    route: 'apAgentHome'
  },
  {
    title: 'Leads',
    icon: 'ni ni-list-index',
    route: 'apAgentLeads'
  },
  {
    title: 'Appointments',
    icon: 'ni ni-list-check',
    route: 'apAgentAppointments'
  },
  {
    title: 'Export Leads',
    icon: 'ni ni-file-xls',
    route: 'ExportLeadsCSV',
  },
]