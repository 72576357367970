import axios from "@/libs/axios"
import {isPlatform, loadingController, toastController} from "@ionic/vue";
import store from "@/store";
import {computed} from "vue";
export default () => {

    const isDesktop = isPlatform('desktop')
    const changeDP = async ( blob ) => {
        let dpData = new FormData()
        dpData.append('dp', blob)
        const loading = await loadingController.create({message:'Uploading dp...'})
        await loading.present()
        let new_DP = ''
        await axios.post('/api/upload_dp', dpData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then( resp => {
            loading.dismiss()
            if( resp.data.success){
                new_DP = resp.data.data.dp
            }
            else{
                throw resp.data.message
            }
        })
        .catch(er => {
            loading.dismiss()
            throw er.response.status+' '+er.response.statusText
        })
        return new_DP
    }
    const changeLang = (lang) => {
        store.dispatch('appConfig/change_language', lang)
        window.location.reload()
    }
    const changeLogo = async ( blob ) => {
        let dpData = new FormData()
        dpData.append('logo', blob)
        const loading = await loadingController.create({message:'Uploading logo...'})
        await loading.present()
        let new_Logo = ''
        await axios.post('/api/advisor/upload_logo', dpData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then( resp => {
            loading.dismiss()
            if( resp.data.success){
                new_Logo = resp.data.data.logo
            }
            else{
                throw resp.data.message
            }
        })
        .catch(er => {
            loading.dismiss()
            throw er.response.status+' '+er.response.statusText
        })
        return new_Logo
    }
    const gmailAuthConsentURL = async () => {
        const loading = await loadingController.create({message:'Please wait...'})
        await loading.present()
        let tAttr = {header: "Error!", message: '', color:"danger", duration: 3500};
        axios.get('/api/advisor/gmail/connect')
            .then( resp => {
                loading.dismiss()
                if( resp.data.success){
                    if(isDesktop){
                        window.location.href = resp.data.data.auth_url
                    }
                    else{
                        window.open(resp.data.data.auth_url, '_blank').focus();
                    }
                }
                else{
                    tAttr.message = resp.data.message
                    toastController.create(tAttr).then((t)=> t.present())
                }
            })
            .catch(er => {
                tAttr.message = er.response ? er.response.status+' '+er.response.statusText : er
                toastController.create(tAttr).then((t)=> t.present())
            })
            .then(() => loading.dismiss())
    }
    const updateUserMeta = (key, val) => {
        return new Promise((resolve, reject)=>{
            axios.post('/api/update_user_meta', {key: key, val: val})
                .then(r=>{
                    if(r.data.success){
                        resolve()
                    }
                    else{
                        reject(r.data.message)
                    }
                })
                .catch(e=>{
                    reject(e.response ? e.response.status+' '+e.response.statusText : e.toString())
                })
        })
    }
    const updateObject = (object, sourceObj, returnObject, firstCall) => {
        try{
            for(let x in sourceObj){
                if(undefined === object[x] || (null === object[x] || null === sourceObj[x])){
                    object[x] = sourceObj[x]
                    continue;
                }
                if(typeof sourceObj[x] === 'object' && !Array.isArray( sourceObj[x])){
                    updateObject(object[x], sourceObj[x], false, false)
                }
                else{
                    object[x] = sourceObj[x]
                }
            }
        }catch (e){
            console.log(e)
        }
        if(returnObject && firstCall){
            return object
        }
    }
    const passwordValidationRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
    const askDesktopNotificationsPermission = () =>{
        return new Promise((resolve, reject)=>{
            if (!("Notification" in window) || Notification.permission === "denied") {
                reject()
            }
            else if( Notification.permission === 'granted'){
                resolve()
            }
            else{
                Notification.requestPermission().then((permission) => {
                    permission === 'granted' ? resolve() : reject()
                })
                    .catch(()=> reject())
            }
        })
    }
    /**
     *
     * @param code Google auth_code
     * @param service which service was requested permissions for! i.e. gmail, calendar etc.
     * @returns {Promise<unknown>}
     */
    const googleSetAuthCode = (code, service) => {
        return new Promise((resolve, reject) => {
            axios.post('/api/set_google_auth_code', {auth_code: code, service: service})
                .then((r)=>{
                    if(r.data.success){
                        resolve()
                        return
                    }
                    reject(r.data.message)
                })
                .catch((e)=>{
                    reject(e.response ? e.response.status+' '+e.response.statusText : e.toString())
                })
        })
    }
    const getServerTime = () => {
        return new Promise((resolve, reject) => {
            axios.get('/api/get_time_now')
                .then((r)=>{
                    if(r.data.success){ resolve(r.data.data) }
                    else{ reject(r.data.message) }
                })
                .catch((e)=>{
                    reject(e.response ? e.response.status+' '+e.response.statusText : e.toString())
                })
        })
    }
    const navBarUserDropdownLinks = computed(()=>{
        let links = {
            profile_settings: {
                route: '',
                params:{},
            },
            account_setting: {
                route: '',
                params: {}
            },
            login_activity:{
                route: '',
                params:{tab: 'activity'},
            }
        }
        switch (store.state.auth.user_role){
            case 'is-advisor':
                links.profile_settings.route = 'Settings';
                links.account_setting.route = 'Settings';
                links.login_activity.route = 'Settings';
                break;
            case 'is-admin':
                links.profile_settings.route = 'adminProfile';
                links.account_setting.route = 'adminProfile';
                links.login_activity.route = 'adminProfile';
                break;
            case 'is-company':
                links.profile_settings.route = 'comSettings';
                links.account_setting.route = 'comSettings';
                links.login_activity.route = 'comSettings';
                break;
        }
        return links
    })
    const getSelectedRecordsForBulkAction = (records)=>{
        let selectedRecords = []
        for(let x in records){
            if( records[x].selected){
                selectedRecords.push( records[x])
            }
        }
        if( selectedRecords.length < 1 ){
            return false
        }
        return selectedRecords
    }
    const selectAll = (records, select)=>{
        for(let x in records){
            records[x].selected = select
        }
    }
    const userRoles = ['is-admin', 'is-advisor', 'is-company', 'is-ap-agent']
    return {
        askDesktopNotificationsPermission,
        changeDP,
        changeLang,
        changeLogo,
        getSelectedRecordsForBulkAction,
        getServerTime,
        gmailAuthConsentURL,
        googleSetAuthCode,
        navBarUserDropdownLinks,
        passwordValidationRegex,
        selectAll,
        updateObject,
        updateUserMeta,
        userRoles,
    }
}