import {useStore} from "vuex";
import {computed} from "vue";

export default () => {

    const store = useStore()
    const userRole = computed(()=> store.state.auth.user_role)
    const endPointByRole = ()=>{
        const endpoints = {
            'is-ap-agent': 'ap_agent',
            'is-admin': 'admin',
            'is-advisor': 'advisor',
            'is-company': 'company',
        }
        return '/api/'+endpoints[userRole.value]
    }
    const currentUserData = computed(()=>{
        switch (userRole.value){
            case 'is-advisor':
                return store.state.advisor_data
            case 'is-admin':
                return store.state.admin_data
            case 'is-company':
            default:
                return store.state.user_data
        }
    })
    return{
        endPointByRole: endPointByRole(),
        userRole,
        currentUserData,
    }
}
