import eTemplatesFunctions from "@/views/email-templates/eTemplatesFunctions";
import store from "@/store"
const {getPlaceholders} = eTemplatesFunctions()

export default [
    {
        path: '/admin',
        name: 'admin',
        component: () => import("@/views/admin/Leads/LeadsList"), //import('@/views/admin/Index'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
            },
        },
    },
    {
        path: '/admin/dashboard',
        name: 'adminDashboard',
        component: () => import("@/views/admin/Leads/LeadsList"),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
            }
        },
    },
    {
        path: "/admin/leads",
        name: "adminLeadsList",
        component: () => import('@/views/admin/Leads/LeadsList'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'LeadsList',
            },
        },
    },
    {
        path: "/admin/leads/returned",
        name: "adminReturnedLeads",
        component: () => import('@/views/admin/Leads/ReturnedLeads'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'ReturnedLeads',
            },
        },
    },
    {
        path: "/admin/leads/assigned",
        name: "adminAssignedLeads",
        component: () => import('@/views/admin/Leads/AssignedLeads'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'LeadsList',
            },
        },
    },
    {
        path: "/admin/leads/:id",
        name: "adminLeadDetails",
        component: () => import('@/views/admin/Leads/LeadDetails'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'LeadDetails',
            }
        },
    },
    {
        path: "/admin/login",
        name: "adminLogin",
        component: () => import('@/views/admin/Login')
    },
    {
        path: "/admin/advisors/:action?/:id?",
        name: "advisors",
        component: () => import('@/views/admin/advisors/Advisors'),
        meta: {
            required_roles: ['is-admin'],
        },
    },
    {
        path: "/admin/advisors/profile/:id/:tab?",
        name: "advisorProfile",
        component: () => import('@/views/admin/advisors/Profile'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Settings',
            },
        },
    },
    {
        path: "/admin/marketplace",
        name: "adminMarketplace",
        component: () => import('@/views/admin/Marketplace'),
        meta: {
            required_roles: ['is-admin'],
        },
    },
    {
        path: "/admin/settings/api_keys",
        name: "adminAPIKeys",
        component: () => import('@/views/admin/Settings/APIKeys'),
        meta: {
            required_roles: ['is-admin'],
        },
    },
    {
        path: "/admin/settings/blocked_phone_numbers",
        name: "adminBlockedPhoneNumbers",
        component: () => import('@/views/admin/Settings/BlockedPhoneNumbers'),
        meta: {
            required_roles: ['is-admin'],
        },
    },
    {
        path: "/admin/profile/:tab?",
        name: "adminProfile",
        component: () => import('@/views/admin/profile/Profile'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Settings',
            },
        },
    },
    {
        path: "/admin/email_templates",
        name: "adminEmailTemplates",
        component: () => import('@/views/email-templates/TemplatesList'),
        meta: {
            required_roles: ['is-admin'],
        },
    },
    {
        path: "/admin/email_templates/:id",
        name: "adminEmailTemplateViewEdit",
        component: () => import('@/views/email-templates/ViewEditTemplate'),
        meta: {
            required_roles: ['is-admin'],
        },
        beforeEnter: async (to, from, next) => {
            if (store.state.general_data.placeholders.length) {
                next()
            } else {
                await getPlaceholders().then(resp => {
                    if (resp.success) {
                        store.commit('commit_placeholders', resp.data)
                    }
                    next()
                })
                    .catch(() => next())
            }
        },
    },
    {
        path: "/admin/invoices",
        name: "adminInvoices",
        component: () => import('@/views/admin/Invoices/Invoices'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Invoices',
            }
        },
    },
    {
        path: "/admin/invoices/:id",
        name: "adminViewInvoice",
        component: () => import('@/views/admin/Invoices/ViewInvoice'),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'ViewInvoice'
            }
        },
    },
    {
        path: "/admin/invoices/states",
        name: "adminInvoicesStates",
        component: () => import('@/views/admin/Invoices/States'),
        meta: {
            required_roles: ['is-admin'],
        },
    },
    {
        path: '/admin/lead/:id/analytics',
        name: 'adminLeadAnalytics',
        component: () => import("@/views/admin/LeadAnalytics/Analytics"),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
            },
        },
    },
    {
        path: '/admin/knowledge_base/',
        component: () => import("@/views/admin/KnowledgeBase"),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
                'is-ap-agent': 'Home',
            },
        },
        children: [
            {
                path: 'category-list',
                name: 'adminKnowledgeBaseCatList',
                component: () => import("@/views/admin/KnowledgeBase/categories/CategoryList"),
            },
            {
                path: 'topics-list',
                name: 'adminKnowledgeBaseTopicsList',
                component: () => import("@/views/admin/KnowledgeBase/topics/TopicList"),
            }
        ],

    },
    {
        path: '/admin/ap_agents',
        name: 'adminAPAgentsList',
        component: () => import("@/views/admin/ApAgents/ApAgents"),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
                'is-ap-agent': 'Home',
            },
        },
    },
    {
        path: '/admin/ap_agents/assigned_leads',
        name: 'adminApAgentsLeads',
        component: () => import("@/views/admin/ApAgents/Leads"),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
                'is-ap-agent': 'Home',
            },
        },
    },
    {
        path: '/admin/ap_agents/appointments',
        name: 'adminApAgentsAppointments',
        component: () => import("@/views/admin/ApAgents/Appointments"),
        meta: {
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
                'is-ap-agent': 'Home',
            },
        },
    },
    {
        path: "/admin/ap_agents/profile/:id/:tab?",
        name: 'adminApAgentProfile',
        component: () => import("@/views/admin/ApAgents/Profile"),
    },
    {
        path: '/admin/companies',
        name: 'adminCompaniesList',
        component: () => import("@/views/admin/companies/Companies"),
        meta:{
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
                'is-ap-agent': 'Home',
            },
        },
    },
    {
        path: '/admin/companies/:id/:tab?',
        name: 'adminCompanyProfile',
        component: () => import("@/views/admin/companies/CompanyProfile"),
        meta:{
            required_roles: ['is-admin'],
            redirect_to: {
                'is-advisor': 'Home',
                'is-ap-agent': 'Home',
            },
        },
    },
    {
        path: '/admin/leads_forms',
        name: 'adminLeadsForms',
        component: () => import("@/views/admin/LeadsForms/LeadsForms"),
        meta:{
            required_roles: ['is-admin'],
        },
    },
    {
        path: '/admin/leads_forms/:id/fields',
        name: 'adminLeadsFormFields',
        component: () => import("@/views/admin/LeadsForms/LeadFormFields"),
        meta:{
            required_roles: ['is-admin'],
        },
    }
]
