<template>
  <em class="icon ni" :class="icon" :style="{color: color, 'font-size': size}"></em>
</template>
<script>
export default {
  name: 'NioIcon',
  props:{
    icon:{
      required: true,
      type: String,
    },
    color: String,
    size: String,
  },
}
</script>
