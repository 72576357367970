import axios from "@/libs/axios"
export default {
    namespaced: true,
    state: {
        notifications:[],
        unread_count: 0,
    },
    actions: {
        markNotificationAsRead: ({commit}, id) => {
            axios.post('/api/mark_notification_read', {id: id})
                .then((resp) =>{
                    if(resp.data.success){
                        commit('MARK_NOTIFICATION_READ', id)
                    }
                })
                .catch()
        },
        getNotifications({commit}){
            axios.get('/api/notifications?page=0')
                .then( resp => {
                    commit('SET_NOTIFICATIONS', resp.data)
                })
                .catch(error => {
                    console.log('Notifications ajax error: '+error)
                })
        }
    },
    mutations: {
        SET_NOTIFICATIONS: (state, notifications) => {
            state.unread_count = notifications.data.unread_count
            for(let x in notifications.data.notifications){
                state.notifications.push(notifications.data.notifications[x])
            }
        },
        PUSH_NEW_NOTIFICATION: (state, notification) => {
            state.notifications.unshift(notification)
            state.unread_count++
        },
        MARK_NOTIFICATION_READ:(state, id) => {
            for(let x in state.notifications){
                if(id === 'all' || state.notifications[x].id === id){
                    state.notifications[x].read_at = 'now'
                    state.unread_count--
                    if(id !== 'all'){
                        break
                    }
                }
            }
        }
    },
    getters: {
        unreadCount: state => {
            return state.unread_count
        },
    }
}
