import {useStore} from "vuex";

export default ()=>{
  const store = useStore()
  const advisorMenu = [
    {
      header: 'Main Menu',
    },
    {
      title: 'Overview',
      icon: 'ni-dashboard',
      route: 'Home'
    },
    {
      title: 'Appointments',
      icon: 'ni ni ni-list-check',
      route: 'Appointments',
    },
    {
      title: 'Leads List',
      icon: 'ni ni-list-index',
      route: 'LeadsList',
    },
    {
      title: 'Returned Leads',
      icon: 'ni ni-file-remove',
      route: 'ReturnedLeads',
    },
    {
      title: 'Export Leads',
      icon: 'ni ni-file-xls',
      route: 'ExportLeadsCSV',
    },
    {
      title: 'Archived leads',
      icon: 'ni ni-archived',
      route: 'ArchivedLeads',
    },
    {
      title: 'Calendar',
      icon: 'ni ni-calendar-alt',
      route: 'Calendar',
    },
    {
      title: 'Invoices',
      //icon: 'ni ni-report-profit',
      icon: 'ni ni-file-docs',
      route: 'Invoices',
      display: store.state.advisor_data.company_id < 1
    },
    /*{
        title: 'Knowledge Base',
        icon: 'ni ni-book',
        route: 'ViewKnowledgeBase',
    },*/
  ]

  return{
    advisorMenu,
  }
}
