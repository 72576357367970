import Echo from "laravel-echo"
import axios from "@/libs/axios";
import store from "@/store"
import {toastController} from "@ionic/vue";
window.pusher = require("pusher-js")
import useNotifications from "@/views/notifications/useNotifications";
import useCommonFunc from "@core/comp-functions/common"
import router from "@/router";
const {askDesktopNotificationsPermission} = useCommonFunc()

const notifyDesktop = (title, body, notificationObj) => {
    const {notificationAction} = useNotifications()
    if (!("Notification" in window)) {
        return;
    }
    if( Notification.permission === 'granted'){
        if(document.hasFocus()){
            return;
        }
        const nI = new Notification(title, {
            body: body,
            icon: require('@/assets/images/logos/logo_small_squre.png'),
        });
        nI.onclick = (e)=>{
            if(notificationObj.link){
                e.preventDefault()
                window.location.href = notificationObj.link
            }
            else if(notificationObj){
                const a = notificationAction(notificationObj, true)
                if(a){
                    router.push(a)
                }
            }
        }
    }
    else if (Notification.permission !== "denied") {
        toastController
            .create({
                header: 'Desktop Notifications',
                message: 'Turn on desktop notifications, so you can get notification even if the CRM window is out of your focus.',
                position: 'top',
                color: 'tertiary',
                buttons: [
                    {
                        side: 'end',
                        text: 'Allow',
                        handler: () => {
                            askDesktopNotificationsPermission().then(()=>{
                                toastController.create({message:'Thank you! you have successfully enabled desktop notifications.', color: 'success', duration: 3000}).then((t)=>t.present())
                            }).catch(()=>{
                                toastController.create({message:'You can always enable notification from your browser settings.', color: 'primary', duration: 3500}).then((t)=>t.present())
                            })
                        }
                    },
                    {
                        text: 'No',
                        role: 'cancel',
                    }
                ]
            }).then((t)=> t.present())
    }
}
const initiateEcho = () => {

    if(store.state.auth.is_logged_in){
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: '49494040404004',
            authEndpoint: process.env.VUE_APP_API_ENDPOINT+'broadcasting/auth',
            auth: {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            },
            //cluster: process.env.MIX_PUSHER_APP_CLUSTER,
            wsHost: process.env.VUE_APP_MIX_PUSHER_SERVER,
            wsPort: 6001,
            wssPort: 6001,
            encrypted: false,
            enabledTransports: ['ws'],
            forceTLS: false,
            disableStats: true,
        });
        const userId = store.state.auth.user_role === 'is-advisor' ? store.state.advisor_data.id : store.state.admin_data.id
        window.Echo.private(`App.Models.User.${userId}`)
            .notification( (notification) => {
                const toast = {duration:4500, color:"tertiary", position: "top"}
                // Get notification data
                axios.get('/api/notification/'+notification.id)
                    .then(resp=>{
                        let nType = notification.type.split('\\').pop();
                        if(nType === 'LeadAssigned'){
                            toast.header = 'Congratulations!'
                            toast.message = 'You have been assigned a new lead'
                        }
                        else if( nType === 'NewInvoice'){
                            toast.header = 'New Invoice'
                            toast.message = 'You have a got a new invoice'
                        }
                        else if( nType === 'ReturnedLeadStatusChanged'){
                            toast.header = 'Lead return'
                            toast.message = 'Your lead return request has been reviewed'
                        }
                        else if( nType === 'AdvisorRegistered'){
                            toast.header = 'New advisor'
                            toast.message = 'A new advisor has signed up'
                        }
                        else if( nType === 'LeadReturned'){
                            toast.header = 'Lead returned'
                            toast.message = `Advisor ${resp.data[0].data.advisor_name} returned a lead`
                        }
                        else if( nType === 'NewLead'){
                            toast.header = 'New lead received'
                            toast.message = `We have received a new lead ${resp.data[0].data.lead_number}`
                        }
                        notifyDesktop(toast.header, toast.message, resp.data[0])
                        store.commit('notifications/PUSH_NEW_NOTIFICATION', resp.data[0])
                        toastController.create(toast).then((t) => t.present())
                    })
                    .catch(e=> {
                        console.log('pusher error', e)
                    })
            })
        window.Echo.channel('everyone')
            .listenToAll((nType, nData)=>{
                notifyDesktop(nData.title, nData.body, nData)
            })
        window.Echo.channel(store.state.auth.user_role === 'is-admin' ? 'admins' : 'advisors')
            .listenToAll((nType, nData)=> {
                notifyDesktop(nData.title, nData.body, nData)
            })
    }
}

export {
    initiateEcho,
}
