import {isPlatform} from "@ionic/vue";
import {PushNotifications} from "@capacitor/push-notifications";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import axios from "@/libs/axios";
import useStoreHelper from "@/store/helper";

export default ()=>{

    const store = useStore()
    const router = useRouter()
    const{userRole} = useStoreHelper()

    const initiatePushNotifications = () => {

        if(!isPlatform('hybrid')){
            return
        }

        PushNotifications.requestPermissions().then(r=>{
            if(r.receive === 'granted'){
                PushNotifications.register()
            }
            else{
                console.log('Notifications permission not granted')
            }
        })

        PushNotifications.addListener('registration', (token) => {
            saveNotificationToken(token.value)
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push received: ' + JSON.stringify(notification));
        });

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',async (notification) => {
            const nData = notification.notification.data
            switch (nData.notification_type){
                case 'AdvisorHasAppointment': // To company users only
                    await router.push({name: 'comLeadDetails', params:{id: nData.lead_id}})
                    break;
                case 'LeadAssigned':
                case 'ReturnedLeadStatusChanged':
                case 'LeadFollowupReminder':
                    await router.push({name: (userRole.value === 'is-advisor' ? 'LeadDetails' : 'apAgentLeadDetails'), params:{ id: nData.lead_id} });
                    break;
                case 'NewInvoice':
                    await router.push({name: 'ViewInvoice', params:{id: nData.id}});
                    break;
                case 'AdvisorRegistered':
                    await router.push({name: 'advisorProfile', params:{id: nData.id}});
                    break;
                case 'LeadReturned':
                case 'NewLead':
                    await router.push({name: 'adminLeadDetails', params: {id: nData.lead_id}});
                    break;
            }
            await store.dispatch('notifications/markNotificationAsRead', nData.notification_id);
        });
    }

    const saveNotificationToken = (token) => {
        if(!store.state.auth.is_logged_in){
            setTimeout(()=> saveNotificationToken(token), 10000);
            return
        }
        axios.post('/api/push_notifications/set_token', {token: token})
            .then(r=>{
                if(!r.data.success){
                    setTimeout(()=> saveNotificationToken(token), 10000);
                }
                else{
                    console.log('Notifications token saved.')
                }
            })
            .catch(er=>{
                console.log('Push N Token error: '+ (er.response ? er.response.status + ' '+er.response.statusText :  'General error'))
                setTimeout(()=> saveNotificationToken(token), 10000);
            })
    }

    return{
        initiatePushNotifications,
    }
}