export default {
    namespaced: true,
    state: {
        registration_data: {
            first_name: '',
            last_name: '',
            password: '',
            c_password: '',
            email: '',
            kifid_number: '',
            phone_no: '',

            company_name: '',
            postcode: '',
            policy_no: '',
            house_no: '',
            ch_commerce_no: '', // Chamber of commerce number,
            afm_no: '',
            google_id: '',
            google_id_token: '',
            dp: '',
            dp_file: '',
            wft_number: '',
        },
    },
    actions: {

    },
    mutations: {
        update_data: (state, data) => {
            for(let x in data){
                state.registration_data[x] = data[x]
            }
        },
    },
}
