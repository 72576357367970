//import { $themeConfig } from '@themeConfig'
import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        lang: (!localStorage.getItem('lang') ? 'nl' : localStorage.getItem('lang')),
        dark_mode: undefined,
    },
    getters: {},
    mutations: {
        CHANGE_LANG: (state, lang) => {
            state.lang = lang
        },
    },
    actions: {
        change_language: (context, lang) => {
            return new Promise((resolve)=> {
                context.commit('CHANGE_LANG', lang)
                localStorage.setItem('lang', lang)
                if (!context.rootState.auth.is_logged_in) {
                    resolve()
                    return
                }
                axios.post('/api/change_lang', {lang: lang})
                    .then(() => {
                        resolve()
                    })
                    .catch(error => {
                        resolve()
                        console.log(error)
                    })
            })
        },
    },
}
