export default [
    {
        path: '/ap_agent/home',
        name: 'apAgentHome',
        component: () => import("@/views/ap_agent/Home"),
        meta:{
            required_roles: ['is-ap-agent'],
            redirect_to: {
                'is-admin' : 'admin',
                'is-advisor': 'Home'
            }
        }
    },
    {
        path: '/ap_agent/leads',
        name: 'apAgentLeads',
        component: () => import("@/views/ap_agent/Leads"),
        meta:{
            required_roles: ['is-ap-agent'],
            redirect_to: {
                'is-admin' : 'adminLeadsList',
                'is-advisor': 'LeadsList'
            }
        }
    },
    {
        path: '/ap_agent/leads/:id',
        name: 'apAgentLeadDetails',
        component: () => import("@/views/ap_agent/LeadDetails/LeadDetails"),
        meta:{
            required_roles: ['is-ap-agent'],
            redirect_to: {
                'is-admin' : 'adminLeadDetails',
                'is-advisor': 'LeadDetails'
            }
        }
    },
    {
        path: '/ap_agent/appointments',
        name: 'apAgentAppointments',
        component: () => import("@/views/ap_agent/Appointments"),
        meta:{
            required_roles: ['is-ap-agent'],
            redirect_to: {
                'is-admin' : 'adminLeadsList',
                'is-advisor': 'LeadsList'
            }
        }
    },
    {
        path: "/ap_agent/advisors/profile/:id/:tab?",
        name: "apAgentAdvisorProfile",
        component: () => import('@/views/admin/advisors/Profile'),
        meta: {
            required_roles: ['is-ap-agent'],
            redirect_to: {
                'is-admin': 'advisorProfile',
                'is-advisor': 'Settings',
            },
        },
    },
]