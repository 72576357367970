import { createRouter, createWebHistory } from '@ionic/vue-router'
import store from "@/store";
import {loadingController, isPlatform, alertController} from "@ionic/vue";
import adminRoutes from "./admin"
import apRoutes from "./appointment_agent"
import companyRoutes from "./company"
import {initiateEcho} from "@/libs/laravel_echo";
import useCommonFunc from '@core/comp-functions/common'
const{userRoles} = useCommonFunc()

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: { // in case user role is not one of the above
        'is-admin' : 'admin',
        'is-company': 'companyHome',
      }
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import("@/views/Home"),
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin' : 'admin',
        'is-ap-agent': 'apAgentHome',
        'is-company': 'companyHome',
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/auth/Login")
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/views/auth/Register")
  },
  {
    path: '/lead_details/:id',
    name: 'LeadDetails',
    component: () => import('@/views/LeadDetails'),
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin': 'adminLeadDetails',
      }
    }
  },
  {
    path: '/leads_list',
    name: 'LeadsList',
    component: () => import('@/views/LeadsList'),
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin': 'adminLeadsList',
      }
    }
  },
  {
    path: '/returned_leads',
    name: 'ReturnedLeads',
    component: () => import('@/views/ReturnedLeads'),
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin': 'adminReturnedLeads',
      }
    }
  },
  {
    path: '/archived_leads',
    name: 'ArchivedLeads',
    component: () => import('@/views/ArchivedLeads'),
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin': 'adminLeadsList',
      }
    }
  },
  {
    path: "/leads/export_csv",
    name: "ExportLeadsCSV",
    component: () => import('@/views/leads-export/LeadsExportCSV'),
  },
  {
    path: '/appointments',
    name: 'Appointments',
    component: () => import('@/views/Appointments'),
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin': 'adminApAgentsAppointments',
        'is-ap-agent': 'apAgentAppointments',
      }
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('@/views/calendar/Calendar'),
    meta:{
      required_roles: ['is-advisor'],
    }
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('@/views/invoices/Invoices'),
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin': 'adminInvoices',
      }
    }
  },
  {
    path: '/invoices/:id',
    name: 'ViewInvoice',
    component: () => import('@/views/invoice/ViewInvoice'),
    meta:{
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin': 'adminViewInvoice',
      }
    }
  },
    {
        path: '/knowledge-base',
        name: 'ViewKnowledgeBase',
        component: () => import('@/views/knowledge-base'),
        meta:{
            required_roles: ['is-advisor'],
            redirect_to: {
                'is-admin': 'adminViewInvoice',
            }
        }
    },
  {
    path: '/settings/:tab?',
    name: 'Settings',
    component: () => import('@/views/Settings'),
    meta: {
      required_roles: ['is-advisor'],
      redirect_to: {
        'is-admin': 'adminProfile',
      },
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/notifications/Notifications')
  },
  {
    path: '/forgot_password/:token?/:reset_token?',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword')
  },
  {
    path: '/terms-of-service',
    name: 'TOS',
    component: () => import('@/views/TOS')
  },
  {
    path: '/maintenance',
    name: 'MaintenanceMode',
    component: () => import('@/views/MaintenanceMode')
  },
  {
    path: '/:role/complete_reg/:activation_key',
    name: 'complete_reg',
    component: () => import("@/views/auth/CompleteRegistration"),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy')
  },
    ...adminRoutes,
    ...apRoutes,
    ...companyRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/404'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const canVisitRoute = (routeMeta) => {
  return !(store.state.auth.user_role && routeMeta.required_roles && !routeMeta.required_roles.includes(store.state.auth.user_role));
}
const loadAfterAuthData = async () => {
  if(store.state.auth.after_auth_data_loaded){
    return Promise.resolve()
  }
  await store.dispatch('get_logged_in_user_profile', {role: store.state.auth.user_role})
      .catch(()=>{
        alertController.create({
          header: 'Error!',
          message: 'Unable to load necessary data.',
          cssClass: isPlatform('desktop') ? 'alert-web' : '',
          backdropDismiss: false,
          buttons:[
            {
              text: 'Reload',
              cssClass: isPlatform('desktop') ? 'alert-btn-info' : '',
              handler: ()=>{
                router.go(0)
              }
            }
          ]
        }).then((a)=> a.present())
        return Promise.reject()
      })
  store.commit('auth/commit_after_auth_data_loaded', true)
  store.dispatch('notifications/getNotifications')
  store.dispatch('get_general_data')
  initiateEcho()
  return Promise.resolve()
}
let loader = null
router.beforeEach(async (to, from, next) => {

  const role = store.state.auth.user_role

  loader = await loadingController.create({showBackdrop: false,cssClass: 'v-router'})
  await loader.present()
  store.commit('verticalMenu/UPDATE_VERTICAL_MENU_ACTIVE', false)
  if(to.meta && !canVisitRoute(to.meta)){
    next(to.meta.redirect_to && to.meta.redirect_to[role] ? {name: to.meta.redirect_to[role]} : 'Home')
    await loader.dismiss()
    return
  }
  let noAuthRequired = ['404', 'ForgotPassword', 'Login', 'Register', 'adminLogin', 'TOS', 'MaintenanceMode', 'complete_reg', 'PrivacyPolicy',]
  // Deny access to these routes, if user is already login
  const denyAuthRoutes = ['ForgotPassword', 'Login', 'Register', 'adminLogin']
  if(noAuthRequired.includes(to.name)){
    next()
  }
  else if(store.state.auth.is_logged_in){
    if(!userRoles.includes(store.state.auth.user_role)){
      next({name: 'Login'})
      return
    }
    let shouldNext = true
    await loadAfterAuthData().then(()=>{
      if(denyAuthRoutes.includes(to.name)){
        shouldNext = {name: role === 'is-admin' ? 'admin' : 'Home'}
      }
    }).catch(()=>{
      shouldNext = false
      loader.dismiss()
    })
    next(shouldNext)
  }
  else{
    next({name: 'Login'})
  }
})
router.afterEach(() => {
  loader.dismiss()
})

export default router
