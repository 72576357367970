import axios from "@/libs/axios";
import LeadFollowupModal from "@/views/components/LeadFollowupModal";
import {isPlatform, modalController} from "@ionic/vue";
import {ref, watch} from "vue";
import {useRoute} from "vue-router";

export default (store) => {

    let advLeadFollowupModalInstance = ref(null)
    const route = useRoute()
    const pingServer = () => {
        if(!store.state.auth.is_logged_in){
            setTimeout(()=> pingServer(), 60000)
            return false
        }
        axios.get('/api/ping')
            .then(async (res)=>{
                if(store.state.auth.user_role === 'is-advisor'){
                    if( res.data.data.counts ){
                        let counts = res.data.data.counts
                        const badges = [
                            {
                                key: 'Appointments',
                                value_key: 'unread_appointments'
                            },
                            {
                                key: 'LeadsList',
                                value_key: 'unread_leads'
                            }
                        ]
                        for(let x in badges){
                            if(counts[badges[x].value_key] !== undefined){
                                store.commit('commit_menu_badges',{key: badges[x].key, value: counts[badges[x].value_key] })
                            }
                        }
                    }
                    if( res.data.data.leads_followups.length && !store.state.advisorLeadsFollowup.modalOpen && !store.state.advisorLeadsFollowup.dontShowPopup && route.name !== 'LeadDetails'){
                        const m = await modalController.create({
                            component: LeadFollowupModal,
                            cssClass: isPlatform('desktop') ? 'modal-web' : '',
                            componentProps: {
                                leadData: res.data.data.leads_followups[0],
                            },
                            backdropDismiss: true,
                        })
                        await m.present()
                        store.commit('advisorLeadsFollowup/UPDATE_MODAL_OPEN', true)
                        advLeadFollowupModalInstance.value = m
                    }
                }
            })
            .catch(()=>{})
            .then( () => setTimeout( () => pingServer(), 60000))
    }
    watch(()=>store.state.advisorLeadsFollowup.modalOpen, (n)=>{
        if(!n){
            advLeadFollowupModalInstance.value.dismiss()
        }
    })
    return {
        pingServer,
    }
}