import { createStore } from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import dataChangeTracker from "./data-change-tracker"
import verticalMenu from './vertical-menu'
import auth from './auth'
import advisor_registration from "./auth/advisor_registration";
import notifications from "./notifications"
import settings from "./settings"
import advisorLeadsFollowup from "./advisor-leads-followup"
import axios from "@/libs/axios";
import useCommonFunc from '@core/comp-functions/common'
const{updateObject} = useCommonFunc()

export default createStore({
  modules: {
    auth,
    app,
    appConfig,
    dataChangeTracker,
    verticalMenu,
    advisor_reg: advisor_registration,
    notifications,
    advisorLeadsFollowup,
    settings,
  },
  state: {
    general_data: {
      leads_statuses: [],
      invoice_statuses: [],
      lead_return_reasons: [],
      lead_uninterested_reasons: [],
      placeholders: [], // currently used in email templates
    },
    user_data:{
      afm_number: '',
      city: '',
      ch_com_number: '',
      company: '',
      company_id: 0,
      company_name: '',
      dp: '',
      email: '',
      first_name: '',
      gmail_access: false,
      has_password: true, // In case of google sign up there be no password
      house_no: '',
      id: 0,
      initials: '',
      last_login: '',
      last_name: '',
      latitude: '',
      logo: '',
      logo_bg: '',
      longitude: '',
      password_updated_at: '',
      phone_number: '',
      post_code: '',
      province: '',
      street: '',
    },
    advisor_data: {
      afm_number: '',
      city: '',
      ch_com_number: '',
      company: '',
      company_id: 0,
      email: '',
      first_name: '',
      gmail_access: false,
      has_password: true, // In case of google sign up there be no password
      house_no: '',
      id: 0,
      initials: '',
      last_login: '',
      last_name: '',
      latitude: '',
      logo: '',
      logo_bg: '',
      longitude: '',
      password_updated_at: '',
      phone_number: '',
      post_code: '',
      province: '',
      street: '',
    },
    admin_data: {
      dp: '',
      email: '',
      first_name: '',
      has_password: true, // In case of google sign up there be no password
      id: 0,
      initials: '',
      last_login: '',
      last_name: '',
      password_updated_at: '',
    },
    current_platform: {
      general: '',
      specific: '',
    },
    menu_badges_data:{
      Appointments: 0,
      LeadsList: 0,
    },
  },
  mutations: {
    SET_CURRENT_PLATFORM(state,data){
      state.current_platform.general  = data.general
      state.current_platform.specific = data.specific
    },
    commit_general_data(state, data){
      if(data.leads_statuses){
        for(let x in data.leads_statuses){
          state.general_data.leads_statuses.push(data.leads_statuses[x])
        }
      }
      if(data.advisor_data){
        for(let x in data.advisor_data){
          state.advisor_data[x] = data.advisor_data[x]
        }
      }
      if(data.invoice_statuses){
        for(let x in data.invoice_statuses){
          state.general_data.invoice_statuses.push(data.invoice_statuses[x])
        }
      }
      if(data.lead_return_reasons){
        for(let x in data.lead_return_reasons){
          state.general_data.lead_return_reasons.push(data.lead_return_reasons[x])
        }
      }
      if(data.lead_uninterested_reasons){
        state.general_data.lead_uninterested_reasons = data.lead_uninterested_reasons
      }
    },
    commit_admin_data(state, data){
      for(let x in data){
        state.admin_data[x] = data[x]
      }
    },
    commit_advisor_data(state, data){
      for(let x in data){
        state.advisor_data[x] = data[x]
      }
    },
    commit_user_data(state,data){
      updateObject(state.user_data, data)
    },
    commit_placeholders(state,data){
      for(let x in data){
        state.general_data.placeholders.push(data[x])
      }
    },
    commit_menu_badges(state, data){
      state.menu_badges_data[data.key] = data.value
    },
  },
  actions: {
    set_general_data({commit}, data){
      commit("commit_general_data", data)
    },
    async get_general_data({commit}){
      return axios.get('/api/general_data')
          .then( resp => {
            commit('commit_general_data', resp.data)
          })
          .catch( error => {
            console.log('General data ajax error: ', error)
          })
    },
    get_logged_in_user_profile({commit}, params ){

      let roleRoutes = {
        'is-advisor': 'advisor',
        'is-admin': 'admin/account',
        'is-ap-agent': 'ap_agent/account',
        'is-company': 'company/account',
      }
      const url = roleRoutes[params.role] || ''
      return new Promise((resolve, reject)=>{
        axios.post('/api/'+url+'/get_profile')
            .then( resp => {
              if(resp.data.success){
                commit('commit_user_data', resp.data.data)
                if(params.role === 'is-advisor'){
                  commit('commit_advisor_data', resp.data.data)
                }
                else{
                  commit('commit_admin_data', resp.data.data)
                }
                resolve(true)
              }else{
                reject(resp.data.message)
              }
            })
            .catch( error => {
              reject(error.response ? error.response.status+' '+error.response.statusText: error)
            })
      })
    },
  },
  strict: process.env.DEV,
})
