import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import i18n from "@/libs/i18n"

import {IonicVue, isPlatform} from '@ionic/vue'
import store from "@/store"

import VTooltipPlugin from "v-tooltip"
import 'v-tooltip/dist/v-tooltip.css'

// Global Components
import {registerGlobalComponents} from './global-components'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
//import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import '@core/scss2/dashlite.scss'
const ionicOptions = {}
if(isPlatform('desktop')){
  ionicOptions.mode = 'ios'
}
const app = createApp(App)
    .use(IonicVue, ionicOptions)
    .use(store)
    .use(router)
    .use(Vuex)
    .use(i18n)
    .use(VTooltipPlugin, {autoHide: false})
app.config.globalProperties.$isHybridPlatform = false; //isPlatform('mobile')
registerGlobalComponents(app);

router.isReady().then(() => {
  app.mount('#app')
});
