export default {
    namespaced: true,
    state: {
        modalOpen: false,
        dontShowPopup: false,
    },
    mutations: {
        UPDATE_MODAL_OPEN(state, open){
            state.modalOpen = open
        },
        DONT_SHOW_POPUP(state, showOrNot){
            state.dontShowPopup = showOrNot
        }
    },
}