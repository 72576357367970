<template>
  <div class="alert" role="alert" :class="classesList">
    <slot></slot>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  props: {
    proStyle: null, // With left colored border
    color: {
      default: 'success',
    },
    filled: Boolean,
    hasIcon: Boolean,
    dismissible: Boolean,
  },
  setup(props){

    const classesList = computed(()=>{
      let classesList = [`alert-${props.color}`]
      if(props.proStyle){
        classesList.push('alert-pro')
      }
      if(props.filled){
        classesList.push('alert-fill')
      }
      if( props.hasIcon){
        classesList.push('alert-icon')
      }
      if( props.dismissible){
        classesList.push('alert-dismissible');
      }
      return classesList
    })
    return {
      classesList,
    }
  }
}
</script>
