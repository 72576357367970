import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {loadingController} from "@ionic/vue";
import {useI18n} from "vue-i18n";
import useStoreHelper from '@/store/helper'

export default ()=>{

    const{t} = useI18n()
    const router = useRouter()
    const store  = useStore()
    const{userRole} = useStoreHelper()

    const notificationTypes = {
        PasswordChanged: {
            icon: 'ni-lock-alt-fill',
            color: 'danger',
        },
        AdvisorRegistered: {
          icon: 'ni-user-add',
          color: 'primary',
        },
        LeadReturned: {
          icon: 'ni-arrow-long-down',
          color: 'warning',
        },
        NewLead: {
            icon: 'ni-plus-circle-fill',
            color: 'success',
        },
    }
    const constructMessage = ( n ) => {

        let message = ''
        switch (n.type){
            case 'LeadAssigned':
                message = `${t('Received a new '+(n.data.lead_type ? n.data.lead_type : 'lead') )} <b>#${ n.data.lead_number}</b>`
                break;
            case 'PasswordChanged':
                message = `${t('Your password was changed')} <br/>at <b>${ n.data.time}, ${t('from')} IP ${ n.data.ip }</b>`
                break;
            case 'NewInvoice':
                message = `${t('You have got a new invoice')}<br/><b>#${n.data.invoice_number}</b>`
                break;
            case 'ReturnedLeadStatusChanged':
                message = t('notifications.returned_lead_status_changed',{action: t(n.data.status === 2 ? 'Rejected' : 'Accepted').toLowerCase() })
                break;
            case 'AdvisorRegistered':
                message = t('notifications.advisor_registered', {name: n.data.name})
                break;
            case 'LeadReturned':
                message = t('notifications.advisor_returned_lead', {name: n.data.advisor_name, number: n.data.lead_number})
                break;
            case 'NewLead':
                message = t('notifications.admin_received_new_lead', {number: n.data.lead_number})
                break;
        }
        return message
    }
    const iconAndBGColor = ( n ) => {
        let data = {color: 'bg-success-dim', icon: 'ni-curve-down-right'}
        try {
            data.color = `bg-${notificationTypes[n.type].color}-dim`
            data.icon  = notificationTypes[n.type].icon
            return data
        }catch (e) {
            if(n.type === 'ReturnedLeadStatusChanged'){
                data.color = n.data.status === 2 ? 'bg-danger-dim' : 'bg-success-dim'
                data.icon  = n.data.status === 2 ? 'ni-cross-sm' : 'ni-done'
            }
            return data
        }
    }
    const notificationAction = ( n, returnAction ) => {
        let action = false
        if(n.type === 'LeadAssigned' || n.type === 'ReturnedLeadStatusChanged'){
            action = {name: (userRole.value === 'is-advisor' ? 'LeadDetails' : 'apAgentLeadDetails'), params:{ id: n.data.lead_id} }
        }
        else if(n.type === 'NewInvoice'){
            action = {name: 'ViewInvoice', params:{id: n.data.id}}
        }
        else if(n.type === 'AdvisorRegistered'){
            action = {name: 'advisorProfile', params:{id: n.data.id}}
        }
        else if( ['LeadReturned', 'NewLead'].includes(n.type)){
            action = {name: 'adminLeadDetails', params: {id: n.data.lead_id}}
        }
        if(returnAction){
            return action
        }else if( action){
            router.push(action)
        }
    }
    const markAsRead = async (n) => {
        if( n === 'all' || !n.read_at){
            const loader = await loadingController.create({message: t('Processing...')})
            if(n === 'all'){
                await loader.present()
            }
            await store.dispatch('notifications/markNotificationAsRead', n === 'all' ? n : n.id)
            await loader.dismiss()
        }
    }

    return {
        constructMessage,
        iconAndBGColor,
        markAsRead,
        notificationAction,
    }
}